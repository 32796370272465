import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Project from './Project'

import Footer from '../Footer';


export default function Services() {
  return (
      <div>
          <Project/>
          <Footer/>
      </div>
  )
}