import React from 'react';
import CardServiceItem from '../CardItem/CardServiceItem';
import './OurClients.css'
function Cards() {
  return (
    <div className='cards'>
      <h1><b>Team Exposure</b></h1>
      <div className='cards_container'>
        <div className='cards_wrapper'>
          <div className="box">
          
          <ul>
            <img className="clentlogo"
              src='images/clientlogos/Majid.png'
              //text='INTERIOR FIT-OUT CONTRACTING'
              //label='Education'
              
              
            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img className="clentlogo"
              src='images/clientlogos/safezone.png'
              //text='DESIGN & PROJECT MANAGEMENT'
              //label='Home Sectors'
             
              
            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img className="clentlogo"
              src='images/clientlogos/HAMRIYAH.png'
             // text='FURNITURE SOLUTIONS'
              //label='Hospitality'
              
              
            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             <img className="clentlogo"
              src='images/clientlogos/jafza.jpg'
             // text='FURNITURE SOLUTIONS'
              //label='Hospitality'
              
            />
            
          </ul><br></br><br></br>
          <ul>
          <img className="clentlogo"
              src='images/clientlogos/dubaisouth.jpg'
              //text='INTERIOR FIT-OUT CONTRACTING'
              //label='Education'
              
              
            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img className="clentlogo"
              src='images/clientlogos/DubaiAirportFreezone.jpg'
              //text='DESIGN & PROJECT MANAGEMENT'
              //label='Home Sectors'
             
              
            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img className="clentlogo"
              src='images/clientlogos/rakez.svg'
             // text='FURNITURE SOLUTIONS'
              //label='Hospitality'
              
              
            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             <img className="clentlogo"
              src='images/clientlogos/AUS.jpg'
             // text='FURNITURE SOLUTIONS'
              //label='Hospitality'
              
            />

          </ul>

          <br></br><br></br>
          <ul>
          <img className="clentlogo"
              src='images/clientlogos/tecomgroup.png'
              //text='INTERIOR FIT-OUT CONTRACTING'
              //label='Education'
              
              
            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img className="clentlogo"
              src='images/clientlogos/dpworld.jpg'
              //text='DESIGN & PROJECT MANAGEMENT'
              //label='Home Sectors'
             
              
            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img className="clentlogo"
              src='images/clientlogos/WSP.jpg'
             // text='FURNITURE SOLUTIONS'
              //label='Hospitality'
              
              
            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             <img className="clentlogo"
              src='images/clientlogos/hilti.png'
             // text='FURNITURE SOLUTIONS'
              //label='Hospitality'
              
            />

          </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;