import React from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
"mdbreact";
import './Slider.css';

const CarouselPage = () => {
  return (
    <MDBContainer>
      <MDBCarousel
      activeItem={1}
      length={3}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src="images/slider/ho2.jpg"
              alt="First slide"
            />
            <div className="hero-conatiner">
            <h2 className="time">Creating Vibrant Spaces Through Continuous Innovation</h2></div>
            
          <MDBMask overlay="black-light" />
          </MDBView>
         
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src="images/slider/co1.jpg"
              alt="Second slide"
            /><div className="hero-conatiner">
            <h2 className="time">Creating Vibrant Spaces Through Continuous Innovation</h2></div>
          <MDBMask overlay="black-strong" />
          </MDBView>
         
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src="images/home/ho1.jpg"
              alt="Third slide"
            /><div className="hero-conatiner">
            <h2 className="time">Creating Vibrant Spaces Through Continuous Innovation</h2></div>
          <MDBMask overlay="black-slight" />
          </MDBView>
         
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
    </MDBContainer>
  );
}

export default CarouselPage;