import React from 'react';
import './CardService.css';
import CardServiceItem from '../CardItem/CardServiceItem';

function Cards() {
  return (
    <div><br></br><br></br><br></br><br></br>
    <h1><b>OUR SERVICES</b></h1>
    <div className='cards'>
      
      <div className='cards_container'>
        <div className='cards_wrapper'>
          
          <ul className='cards_items'>
            <CardServiceItem
              src='images/fitout.jpg'
            text='INTERIOR FIT-OUT CONTRACTING'
              //label='Education'
              
              
            />
            <CardServiceItem
              src='images/furniture.jpg'
              text='DESIGN & PROJECT MANAGEMENT'
              
             
              
            />
            <CardServiceItem
             src='images/hospitality/hos2.jpg'
              text='FURNITURE SOLUTIONS'
              //label='Hospitality'
              
              
            />
            
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Cards;