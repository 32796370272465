import React, { Component } from 'react';
import './util.css'
import './main.css'
import Footer from '../Footer';
import emailjs from 'emailjs-com';
import swal from 'sweetalert';

class Form extends Component {
render(){
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('gmail', 'template_dmtkrko', e.target,'user_AwpWjl8YjIAQa914DN6Be')
          .then((result) => {
			swal({
				title: "Done!",
				text: "We will get back to you shortly!",
				icon: "success",
				timer: 3500,
				button: false
			  })
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      }
return(
    <div>
   
	<div class="container-contact100">
		<div class="wrap-contact100">
			<form class="contact100-form validate-form"  onSubmit={sendEmail}>
				<span class="contact100-form-title">
					Send Us A Message
				</span>

				<label class="label-input100" for="first-name">Tell us your name *</label>
				<div class="wrap-input100 rs1-wrap-input100 validate-input" data-validate="Type first name">
					<input id="first-name" class="input100" type="text" name="name" placeholder="name" required/>
					<span class="focus-input100"></span>
				</div>
				<div class="wrap-input100 rs2-wrap-input100 validate-input" data-validate="Type last name">
					<input class="input100" type="text" name="name" placeholder="Last name" required/>
					<span class="focus-input100"></span>
				</div>

				<label class="label-input100" for="email">Enter your email *</label>
				<div class="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
					<input id="email" class="input100" type="text" name="email" placeholder="Eg. example@email.com" required/>
					<span class="focus-input100"></span>
				</div>

				<label class="label-input100" for="phone">Enter phone number</label>
				<div class="wrap-input100">
					<input id="phone" class="input100" type="text" name="phone" placeholder="Eg. +1 800 000000" required/>
					<span class="focus-input100"></span>
				</div>

				<label class="label-input100" for="message">Message *</label>
				<div class="wrap-input100 validate-input" data-validate = "Message is required">
					<textarea id="message" class="input100" name="message" placeholder="Write us a message" required></textarea>
					<span class="focus-input100"></span>
				</div>

				<div class="container-contact100-form-btn">
					
					<button class="contact100-form-btn">
						Send Message
					</button>
				</div>
			</form>

			<div class="contact100-more flex-col-c-m" >
				<div class="flex-w size1 p-b-47">
					<div class="txt1 p-r-25">
						<span class="lnr lnr-map-marker"></span>
					</div>

					<div class="flex-col size2">
						<span class="txt1 p-b-20"><i class='fa fa-map-marker' />
							&nbsp;&nbsp;Address
						</span>

						<span class="txt3">
                        TIMEBOX Design & Project Management LLC<br></br>
                        P.O.Box : 296360 <br></br>
					    18th floor , Burjuman Business Tower, Dubai , UAE
                       
						</span>
					</div>
				</div>

				<div class="dis-flex size1 p-b-47">
					<div class="txt1 p-r-25">
						<span class="lnr lnr-phone-handset"></span>
					</div>

					<div class="flex-col size2">
						<span class="txt1 p-b-20"><a href="tel:+971 585906827">
    <i class='fa fa-phone' /></a>
							&nbsp;&nbsp;Lets Talk
						</span>

						<span class="txt3"><a href="tel:+971 585906827" >
                        +971 585906827</a>
						</span>
					</div>
				</div>

				<div class="dis-flex size1 p-b-47">
					<div class="txt1 p-r-25">
						<span class="lnr lnr-envelope"></span>
					</div>

					<div class="flex-col size2">
						<span class="txt1 p-b-20"><a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@timeboxuae.com &su=SUBJECT&body=BODY" target="_blank">
    <i class='fa fa-envelope' /></a>&nbsp;
							General Support
						</span>

						<span class="txt3"><a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@timeboxuae.com &su=SUBJECT&body=BODY" target="_blank">
                        info@timeboxuae.com </a>
						</span><br></br>
                        <span>&nbsp;&nbsp; <a href="https://www.linkedin.com/company/timebox-interiors" target="_blank"> <i class='fab fa-linkedin' target="_blank" /></a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.facebook.com/Timebox-Interiors-101352141717272/" target="_blank"><i class='fab fa-facebook' /></a>
						&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://twitter.com/timeboxuae" target="_blank"> <i class='fab fa-twitter' /></a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.instagram.com/timeboxinteriors/?hl=en" target="_blank"><i class='fab fa-instagram' /></a>
   </span>
					</div>
				</div>
			</div>
		</div>
	</div>
    
      <Footer/>
    </div>


)}
}

export default Form