import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
   
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/retail/retail.jpg'
              text='Managed by our team members'
              label='Retail'
              path='/retail'
            />
        
          </ul>
          
        </div>
      </div>
    </div>
  );
}

export default Cards;