import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Slider from '../Slideview/Slider';
import CardService from '../CardItem/CardService'
import Footer from '../Footer';
import CardOffice from '../CardItem/CardOffice'
import Project from './Project'
import OurClients from '../cleints/OurClients';
import './Home.css'

class Home extends React.Component {
  render(){
    
  
  return (
      <div>
       <div className="whts">
         <a href="https://api.whatsapp.com/send?phone=+971 585906827" target="_blank">
          <img src="images/whatsapp.png"  style={{width:"60px",height:"60px"}}/></a>
        </div>
      <Slider/>
      <Project />
      <CardService/>
      <OurClients/>
      <Footer />
      </div>
  );
}
}

export default Home;