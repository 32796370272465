import React from 'react';
import '../../App.css';
import CardService from '../CardItem/CardService';
import Footer from'../Footer'


export default function Services() {
  return (
      <div>
          <CardService/><br></br><br></br>
          <Footer/>
      </div>
  )
}