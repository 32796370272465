import React from 'react'
import './About.css'
import Footer from '../Footer'

function About() {
    return (
        <div><br></br><br></br>
            <div id="wrapper">
    <section id="about-text">
   
      <h3 style={{fontSize: "3vw"}}>About Us</h3>  <br></br><br></br>
      <p>Timebox is an Interior fit-out solution provider with registered Corporate office in the United Arab Emirates.</p>
      <p>An incredibly experienced team serving the Corporate/Office, Retail, F&B, Hospitality,Education,Home and Health Care sectors.
      We provide end to end solutions by managing the project from its concept stage to obtaining occupational certificates.</p>
      <br></br><br></br>
      <h3 style={{fontSize: "3vw"}}>Why we are special</h3>  <br></br>

      <p>We provide a combination of virtual and physical project management services with high and challenging propositions.
      Our team executes projects with a green building project management approach.
       At every stage of a project, we apply internationally recognized and result oriented project management tools.
      Highly experienced in the UAE market, managing multifaceted interior fit-out projects, our team is known for managing complexities.</p>
   <br></br><br></br>
      <h3 style={{fontSize: "3vw"}}>Vision and Mission  </h3>  <br></br>

      <p>Our Vision is to "Create a global brand in the interior fit-out industry.” </p><br></br>
      <p>Our Mission is to "Provide the best competitive interior fit-out services in the region by continuously innovating and adapting to the ever-changing client requirements without compromising the sustainable development goals."</p>
      <br></br><br></br>

     


<br></br><br></br>
      </section></div>
            <Footer/>
        </div>
    )
}

export default About

