import React from 'react';
import './App.css';
import Navbar from './components/Navbar'
import Home from './components/pages/Home';
import Projects from './components/pages/Projects'
import Services from './components/pages/Services'
import { HashRouter, Route, Switch} from 'react-router-dom';
import About from './components/pages/About';
import CardOffice from './components/CardItem/CardOffice'
import CardHealthcare from './components/CardItem/CardHealthcare'
import CardEducation from './components/CardItem/CardEducation'
import CardHomesector from './components/CardItem/CardHomesector'
import CardHospitality from './components/CardItem/CardHospitality'
import Cardfb from './components/CardItem/Cardfb'
import Cardretail from './components/CardItem/Cardretail'
import Form from './components/pages/Form';

function App() {
  return (
    <>
    
      <HashRouter>
      <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
     
          <Route path='/services'  component={Services} />
          <Route path='/aboutus'  component={About} />
          <Route path='/contacts'  component={Form} />
          <Route path='/project'  component={Projects} />
          <Route path='/office' component={CardOffice} />
          <Route path='/healthcare'  component={CardHealthcare} />
          <Route path='/education' component={CardEducation} />
          <Route path='/homesector'  component={CardHomesector} />
          <Route path='/hospitality'  component={CardHospitality} />
          <Route path='/f&b' component={Cardfb} />
          <Route path='/retail' component={Cardretail} />
         
        </Switch>
      </HashRouter>
    </> 
  );
}

export default App;