//import React, { Component } from "react";
//import Slider from "react-slick";
//import Footer from "../Footer";
//export default class SimpleSlider extends Component {
//  render() {
//    const settings = {
//      dots: true,
//      infinite: true,
//      speed: 500,
//      slidesToShow: 1,
//      slidesToScroll: 1,
//      arrows: false
//
//    };
//    return (
//      <div>
//
//        <Slider {...settings}>
//          <div>
//            <img className="officeimg" src="images/retail/retail.jpg" alt="officeimg"/>
//          </div>
//          <div>
//          <img className="officeimg" src="images/retail/retail2.jpg" alt="officeimg"/>
//          </div>
//          <div>
//          <img className="officeimg" src="images/retail/retail3.jpg" alt="officeimg"/>
//          </div>
//          <div>
//          <img className="officeimg" src="images/retail/retail4.jpg" alt="officeimg"/>
//          </div>
//          <div>
//          <img className="officeimg" src="images/retail/retail5.jpg" alt="officeimg"/>
//          </div>
//
//        </Slider><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
//        <Footer/>
//      </div>
//    );
//  }
//}




import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './CardOffice.css'
import Footer from "../Footer"


const photos = [
  {
      name : 'Photo 1',
      url : "images/retail/retail.jpg"
  },
  {
      name : 'Photo 2',
      url : "images/retail/retail2.jpg"
  },
  {
      name : 'Photo 3',
      url : "images/retail/retail3.jpg"
  },
  {
       name : 'Photo 4',
       url : "images/retail/retail4.jpg"
  },

    {
           name : 'Photo 5',
           url : "images/retail/retail5.jpg"
      }

]

class SimpleSlider extends Component {
  render(){
    const settings = {
      dots:true,
      fade: true,
      infinite:true,
      speed:500,
      sliderToShow:1,
      arrow:true,
      sliderToScroll:1,
      className:"slides"
    }
  return (
    <div className="App">
      <Slider {...settings}>
      {photos.map((photo) => {
        return(
          <div>
          <img className="officeimg" src={photo.url}/>
          </div>
        )
      })}
      </Slider> <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
      <Footer/>
    </div>

  );
}
}

export default SimpleSlider;