import React,{Component} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Project.css"
import Cards from '../Cards'
import Cards2 from '../Cards2'
import Cards3 from '../Cards3'
import Cards4 from '../Cards4'
import Cards5 from '../Cards5'
import Cards6 from '../Cards6'
import Cards7 from '../Cards7'
/*class SimpleSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider {...settings}>
        <div>
          <h3><Cards/></h3>
        </div>
        <div>
          <h3><Cards2/></h3>
        </div>
        <div>
          <h3><Cards3/></h3>
        </div>
        <div>
          <h3><Cards/></h3>
        </div>

      </Slider>
    );
  }
}
export default SimpleSlider;*/
export default class AutoPlay extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3500,
        autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
        pauseOnHover: true,
        arrows:true,
        className:"slides"
      };
      return (
          <div><br></br><br></br><br></br><br></br>
              <h1><b>PROJECTS</b></h1>
        <Slider {...settings}>
          <div>
            <h3><Cards/></h3>
          </div>
          <div>
            <h3><Cards2/></h3>
          </div>
          <div>
            <h3><Cards3/></h3>
          </div>
          <div>
            <h3><Cards4/></h3>
          </div>
          <div>
            <h3><Cards5/></h3>
          </div>
          <div>
            <h3><Cards6/></h3>
          </div>
          <div>
            <h3><Cards7/></h3>
          </div>

         
        </Slider>
        <br></br><br></br>
        </div>
      );
    }
  }